
import { Component, Vue, Watch } from 'vue-property-decorator'
import { NavBar, Field } from 'vant'
import { UserService } from '@api/mine/UserService/index.api'
import { loginService } from '@/api/mine/login/index.api'
import { getAppType, setToken } from '@/utils/cookies'
import { Mutation } from 'vuex-class'

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field
  }
})
export default class points extends Vue {
  @Mutation('setUserInfo') public setUserInfo: any

  public points = {}

  public CardNo = ''

  public logList = []

  public signinfo = {}

  public async getPoints() {
    try {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      let {
        data: { ReturnJson }
      } = await UserService({ Action: 'GetNingMengUserData' })

      this.points = JSON.parse(ReturnJson)
    } finally {
      this.$toast.clear()
    }
  }

  public async onExchange() {
    if (!this.CardNo.trim()) return this.$toast('请先输入兑换码')
    try {
      let res = await UserService({ Action: 'BindIntegrationCard', CardNo: this.CardNo })

      this.$toast({
        message: '积分兑换成功',
        type: 'success'
      })
      this.$set(this, 'CardNo', '')
      this.getPoints()
      this.getLog()
    } finally {
    }
  }

  public async getLog() {
    try {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      let {
        data: { ReturnJson }
      } = await UserService({ Action: 'QueryIntegrationLog' })

      this.logList = JSON.parse(ReturnJson)
    } finally {
      this.$toast.clear()
    }
  }

  public async getNingMengUserSignData() {
    try {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      let {
        data: { ReturnJson }
      } = await UserService({ Action: 'GetNingMengUserSignData' })

      this.signinfo = JSON.parse(ReturnJson)
    } finally {
      this.$toast.clear()
    }
  }

  public async onSignInNow() {
    try {
      let res = await UserService({ Action: 'UserSignCard' })

      this.getPoints()
      this.getLog()
      this.getNingMengUserSignData()
      this.$toast.success('签到成功')
    } finally {
    }
  }

  async getWXAuth(code) {
    let { data } = await loginService({
      Action: 'LoginByWeChat',
      AppType: getAppType(),
      Code: code
    })

    if (data.Token) {
      this.setUserInfo(data.ReturnJson)
      setToken(data.Token)
      this.getPoints()
      this.getLog()
      this.getNingMengUserSignData()
    }
  }

  created() {
    if (this.$route.query.logincode) {
      this.getWXAuth(this.$route.query.logincode)
    } else {
      this.getPoints()
      this.getLog()
      this.getNingMengUserSignData()
    }
  }
}
